import React from "react";

import FencersAngled from "./assets/FencersAngled.jpg";
import AppStore from "./assets/AppStore.png";
import GooglePlayBadge from "./assets/google-play-badge.png";
import LiveScorerPicture from "./assets/live-scorer.png";
import MobileEditorPicture from "./assets/mobile-editor.png";
import HSMeetPicture from "./assets/hs-meet.png";
import CollegeEditorPicture from "./assets/college-editor.png";

function App() {
    return (
        <div className="App">
            <header className="header">
                <img src={ FencersAngled } alt="Background of fencers fencing" />
                <h1 style={{ pointerEvents: "none" }}>Touch By Touch</h1>
                <nav>
                    <a href="https://hs.tbtfencing.com" target="_blank" rel="noreferrer"><div><div className="navText">High School</div></div></a>
                    <a href="https://college.tbtfencing.com" target="_blank" rel="noreferrer"><div><div className="navText">College</div></div></a>
                </nav>
            </header>
            <article className="article">
                <div>
                    <h3>High school and college fencing made easy</h3>
                    <p>
                        TouchByTouch is a free service designed for high school and college fencing coaches to manage their teams and dual meets.
                        Through the website and companion app, TouchByTouch enables coaches and fencers to load team rosters, score bouts, and
                        keep track of how meets are going in real-time. Live-scored meets are also broadcasted publicly for spectators to follow
                        along at home.
                    </p>
                </div>
                <img src={ HSMeetPicture } alt="High school meet info on website" />
            </article>
            <article className="article" style={{ backgroundColor: "#EEE", flexDirection: "row-reverse" }}>
                <div style={{ maxWidth: 500 }}>
                    <h3>Let your fencers manage the team</h3>
                    <p>
                        TouchByTouch streamlines the workflow for the coach as much as possible. As soon as you create your team, you can assign
                        a team manager to handle working with TouchByTouch for you, including managing your team's roster, creating dual meets,
                        and more.
                    </p>
                </div>
                <img src={ CollegeEditorPicture } alt="College team roster editor on website" />
            </article>
            <article className="article">
                <div>
                    <h3>Score dual meets on the go</h3>
                    <p>
                        TouchByTouch comes with a fully featured scoring service to make recording meet records easy. You can add and remove
                        touches, cards, and time with a simple swipe, as well as substitute fencers and set bout priority. Additionally, scoring
                        at a dual meet allows spectators to follow along with meet progress in real time.
                    </p>
                </div>
                <img src={ LiveScorerPicture } alt="Live scoring dual meet on mobile" />
            </article>
            <article className="article" style={{ backgroundColor: "#EEE", flexDirection: "row-reverse" }}>
                <div>
                    <h3>Manage on a computer or your phone</h3>
                    <p>
                        All of TouchByTouch's functionality is accessible through both the website and the mobile app, available for both Android
                        and iOS. You can type up or upload your roster through the ease of a computer, while scoring dual meets on the go through
                        your mobile phone.
                    </p>
                </div>
                <img src={ MobileEditorPicture } alt="Editing team roster on mobile" />
            </article>
            <article className="article">
                <div style={{ maxWidth: 600 }}>
                    <h2 style={{ textAlign: "center", fontSize: 36 }}>Use TouchByTouch now</h2>
                    <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-around" }}>
                        <section className="appList">
                            <header>High School</header>
                            <a href="https://hs.tbtfencing.com" target="_blank" rel="noreferrer"><div className="linkButton">Visit the website</div></a>
                            <a className="blah" href="https://play.google.com/store/apps/details?id=live.touchbytouch" target="_blank" rel="noreferrer"><img style={{ marginTop: 10 }} src={ GooglePlayBadge } alt="Download on Android" /></a>
                            <a className="blah" href="https://apps.apple.com/us/app/touch-by-touch/id1530755543"><img src={ AppStore } alt="Download on App Store" /></a>
                        </section>
                        <section className="appList">
                            <header>College</header>
                            <a href="https://college.tbtfencing.com" target="_blank" rel="noreferrer"><div className="linkButton">Visit the website</div></a>
                            <a className="blah" href="https://play.google.com/store/apps/details?id=live.touchbytouchcollege" target="_blank" rel="noreferrer"><img style={{ marginTop: 10 }} src={ GooglePlayBadge } alt="Download on Android" /></a>
                            <a className="blah" href="https://apps.apple.com/us/app/touch-by-touch-college-fencing/id6451499719"><img src={ AppStore } alt="Download on App Store" /></a>
                        </section>
                    </div>
                </div>
            </article>
            <footer>
                <p style={{ fontWeight: 500, fontSize: "1rem", marginBottom: 5 }}>Contact us: <a href="mailto:tbt@redpandafencing.org">tbt@redpandafencing.org</a></p>
                <p style={{ color: "rgba(0, 0, 0, 0.6)", fontSize: "0.875rem" }}>Sponsored by Red Panda Fencing Inc.</p>
            </footer>
        </div>
    );
}

export default App;
